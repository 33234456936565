import React, { Component } from "react";
import './Awards.css';
import profile from "../../assets/images/profile.jpg";

export class Awards extends Component {
    render() {
        return(
          <section id="awards">
            <p className="head">Recognitions</p>
            <div className="list">
              <p className="sub-head">Awards / Honors</p>
              <table>
                <tbody>
                  <tr>
                    <td className="col1">2024</td>
                    <td>Lowell Milken Center for Unsung Heroes ArtEffect $6000 Grand Prize "Visions of Light"</td>
                  </tr>
                  <tr>
                    <td className="col1">2024</td>
                    <td>National Art Honor Societies Juried Exhibition Selection "Shared Disconnection" (125 works chosen out of 2800 submissions)</td>
                  </tr>
                  <tr>
                    <td className="col1">2023</td>
                    <td>Scholastics Art and Writing: 3 National Silver Keys</td>
                  </tr>
                  <tr>
                    <td className="col1">2023</td>
                    <td>New York Times International Multimedia Challenge Runner-up "Shared Disconnection" (35 works chosen out of 4000+ submissions)</td>
                  </tr>
                  <tr>
                    <td className="col1">2023</td>
                    <td>BSD District Art Show 1ST Place (Digital Drawing, Painting)</td>
                  </tr>
                  <tr>
                    <td className="col1">2023</td>
                    <td>The Joongang Korea Daily Student Art Contest honorable mention</td>
                  </tr>
                  <tr>
                    <td className="col1">2023</td>
                    <td>The Joongang Korea Daily Studio Video Contest (animation) 2ND Place</td>
                  </tr>
                  <tr>
                    <td className="col1">2022</td>
                    <td>NASA Langley Student Art Contest National 2ND Place</td>
                  </tr>
                  <tr>
                    <td className="col1">2022</td>
                    <td>Wildlife Forever Art of Conservation Songbird Contest 2ND Place State Winner</td>
                  </tr>
                </tbody>
              </table>
              <br /><br />
              <p className="sub-head">Exhibitions</p>
              <table>
                <tbody>
                  <tr>
                    <td className="col1">June 2024</td> 
                    <td>Unsung Heroes ArtEffect Exhibition<br /><span className="sub-text">Lowell Milken Center 1 South Main, Fort Scott, KS 66701</span></td>
                  </tr>
                  <tr>
                    <td className="col1">June 2024</td>
                    <td>Cat Art Show "Whiskers in the Sunshine"<br /><span className="sub-text">Yuan Ru Art Center 12737 Bel-Red Rd #100, Bellevue, WA 98005</span></td>
                  </tr>
                  <tr>
                    <td className="col1">May 2024</td>
                    <td>Congressional Art Competition (District 9)<br /><span className="sub-text">Wing Luke Museum 719 S King St, Seattle, WA 98104</span></td>
                  </tr>
                  <tr>
                    <td className="col1">May 2024</td>
                    <td>Young Visionaries of the East Side <br /><span className="sub-text">Yuan Ru Art Center 12737 Bel-Red Rd #100, Bellevue, WA 98005</span></td>
                  </tr>
                  <tr>
                    <td className="col1">November 2023</td>
                    <td>Gallery B612 Youth Art Exhibition<br /><span className="sub-text">Gallery B612 1915 1st Ave S, Seattle, WA 98134</span></td>
                  </tr>
                  <tr>
                    <td className="col1">May 2023</td>
                    <td>Congressional Art Competition (District 9)<br /><span className="sub-text">Wing Luke Museum 719 S King St, Seattle, WA 98104</span></td>
                  </tr>
                  <tr>
                    <td className="col1">April 2023</td>
                    <td>Young Visionaries of the East Side <br /><span className="sub-text">Yuan Ru Art Center 12737 Bel-Red Rd #100, Bellevue, WA 98005</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        );
    }
}

export default Awards;