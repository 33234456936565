import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Nav } from '../Nav/Nav';
import './Header.css';
import logo from "../../assets/logos/logo2.png";

export class Header extends Component {

  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.state = {
      showMenu: false,
  };
  }
    
  toggleMenu() {
    if (this.state.showMenu) {
      this.setState({showMenu: false});
    } else {
      this.setState({showMenu: true});
    }
  }

  closeMenu() {
    this.setState({showMenu: false});
  }

  render() {
    return(
      <header className="header">
        <nav className="navbar">
          <div className="nav-logo">
            <Link to="/" onClick={this.closeMenu}><img src={logo} /></Link>
          </div>
          <ul className={`nav-menu ${this.state.showMenu ? 'active' : ''}`}>
              <li className="nav-item">
                <Link to="/about" className="nav-link" onClick={this.toggleMenu}>About</Link>
              </li>
              <li className="nav-item">
                  <Link to="/art-works" className="nav-link" onClick={this.toggleMenu}>Art Works</Link>
              </li>
              <li className="nav-item">
                  <Link to="/awards" className="nav-link" onClick={this.toggleMenu}>Recognitions</Link>
              </li>
              <li className="nav-item">
                  <Link to="/contact" className="nav-link" onClick={this.toggleMenu}>Contact</Link>
              </li>
          </ul>
          <div className={`hamburger ${this.state.showMenu ? 'active' : ''}`} onClick={this.toggleMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;