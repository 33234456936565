import React, { Component } from "react";
import './Contact.css';
import insta from "../../assets/icons/insta1.png";
import tiktok from "../../assets/icons/tiktok1.png";

export class Contact extends Component {

  componentDidMount() {

    document.getElementById('contactForm').addEventListener('submit', function (event) {
      
      event.preventDefault();
  
      // Clear previous errors
      const errorElements = document.querySelectorAll('.error-message');
      errorElements.forEach(el => el.style.display = 'none');
  
      // Get form values
      const name = document.getElementById('name').value.trim();
      const email = document.getElementById('email').value.trim();
      const phone = document.getElementById('phone').value.trim();
      const message = document.getElementById('message').value.trim();
  
      // Validation flags
      let isValid = true;
  
      // Name validation
      if (name === '') {
          document.getElementById('nameError').textContent = 'Name is required';
          document.getElementById('nameError').style.display = 'block';
          isValid = false;
      }
  
      // Email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email === '' || !emailPattern.test(email)) {
          document.getElementById('emailError').textContent = 'Valid email is required';
          document.getElementById('emailError').style.display = 'block';
          isValid = false;
      }
  
      // Phone validation
      const phonePattern = /^[0-9]{10}$/;
      if (phone === '' || !phonePattern.test(phone)) {
          document.getElementById('phoneError').textContent = 'Valid phone number is required';
          document.getElementById('phoneError').style.display = 'block';
          isValid = false;
      }
  
      // Message validation
      if (message === '') {
          document.getElementById('messageError').textContent = 'Message is required';
          document.getElementById('messageError').style.display = 'block';
          isValid = false;
      }
  
      // If form is valid, you can submit it or perform any other action
      if (isValid) {
        const overlay = document.getElementById('overlay');
        overlay.innerHTML = "Sending...";
        overlay.style.display = 'block';
        
        setTimeout(() => {
          overlay.innerHTML = "Message has been sent!";

          setTimeout(() => {
            overlay.style.display = 'none';
          }, 2000);
        }, 2000);

        // need to store the message
      }
    });
  }
  
  render() {
    return(
      <section id="contact">
        <p className="head">Contact</p>
        <div className="row">
          <div className="column greeting">
            <p className="greeting-head">We’d Love to Hear from You!</p>
            <p>Have a question, feedback, or just want to say hello? Feel free to get in touch with us using the form below. Our team will get back to you as soon as possible!</p>
            <div className="contact-info">
              <div className="email">&#x1F4E7; <a href="mailto:rachel.sh.han@gmail.com">rachel.sh.han@gmail.com</a></div>
              <div className="phone">&#9742;&nbsp; 425-543-4790</div>
            </div>
            <div className="social">
              <a href="https://www.instagram.com/almond.uyoo?igsh=MWhiY3pzazJwd3dpcw%3D%3D&utm_source=qr"><img src={insta} width="30px" height="auto" /></a>
              &nbsp;&nbsp;&nbsp;
              <a href="https://www.tiktok.com/@almond.uyoo?_t=8qrCSTzIPba&_r=1"><img src={tiktok} width="25px" height="auto" /></a>
            </div>
          </div>
          <div className="column">
            <div className="contact-form-container">
              <form id="contactForm">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" 
                          name="name" 
                          placeholder="Your Name" required />
                    <span className="error-message" id="nameError"></span>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" 
                          name="email" 
                          placeholder="Your Email" required />
                    <span className="error-message" id="emailError"></span>
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input type="tel" id="phone" 
                          name="phone" 
                          placeholder="Your Phone Number" required />
                    <span className="error-message" id="phoneError"></span>
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea id="message" 
                              name="message" 
                              placeholder="Your Message" 
                              rows="5" required></textarea>
                    <span className="error-message" 
                          id="messageError"></span>
                </div>
                <button type="submit" 
                        className="submit-button">
                  Send Message
                </button>
              </form>
              <div className="overlay" id="overlay">fff</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;